/**
 * @module components/theme/Unauthorized/Unauthorized
 */

import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';
import { getBaseUrl } from '@plone/volto/helpers';

/**
 * unauthorized function.
 * @function Unauthorized
 * @returns {string} Markup of the unauthorized page.
 */
const Unauthorized = ({ history }) => {
  let location = useLocation();
  const [count, setCount] = useState(5);

  React.useEffect(() => {
    if (count <= 0) {
      history.push('/cont/login');
    }
    setTimeout(() => setCount(count - 1), 1000);
  }, [count, history]);

  return (
    <Container className="view-wrapper">
      <h1>
        <FormattedMessage id="Unauthorized" defaultMessage="Unauthorized" />
      </h1>
      <p className="description">
        <FormattedMessage
          id="You are trying to access a protected resource, please {login} first."
          defaultMessage="You are trying to access a protected resource, please {login} first."
          values={{
            login: (
              <Link to={`${getBaseUrl(location.pathname)}/login`}>
                <FormattedMessage id="log in" defaultMessage="log in" />
              </Link>
            ),
          }}
        />
      </p>
      <p>
        <FormattedMessage
          id="You will be redirected to the login form in {count} seconds."
          defaultMessage="You will be redirected to the login form in {count} seconds."
          values={{
            count: <strong>{count}</strong>,
          }}
        />
      </p>
      <p>
        <FormattedMessage id="Thank you." defaultMessage="Thank you." />
      </p>
    </Container>
  );
};

export default withServerErrorCode(401)(Unauthorized);
