import React from 'react';
import { useSelector } from 'react-redux';
import MemberView from './MemberView';
import MedicView from './MedicView';
import AdminView from './AdminView';

const AccountView = (props) => {
  const roles = useSelector((state) => state.users?.user?.roles || []);
  const medic = roles?.includes('Reviewer');
  const manager = roles?.includes('Manager');

  return manager ? (
    <AdminView {...props} />
  ) : medic ? (
    <MedicView {...props} />
  ) : (
    <MemberView {...props} />
  );
};

export default AccountView;
