import React from 'react';
import { FormattedMessage } from 'react-intl';

const AdminView = (props) => {
  return (
    <div className="ui container">
      <FormattedMessage
        id="Admin account here"
        defaultMessage="Admin account here"
      />
    </div>
  );
};

export default AdminView;
