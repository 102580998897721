export const Products = {
  // 1. CT NATIV
  10100: {
    title: '1 SEGMENT',
    description: 'exemplu: Abdomen',
    group: 'CT NATIV',
    subgroup: 'first opinion',
    price: 250,
  },
  10200: {
    title: '2 SEGMENTE',
    description: 'exemplu: Abdomen + Pelvis',
    group: 'CT NATIV',
    subgroup: 'first opinion',
    price: 325,
  },
  10300: {
    title: '3 SEGMENTE',
    description: 'exemplu: Torace, Abdomen si Pelvis',
    group: 'CT NATIV',
    subgroup: 'first opinion',
    price: 400,
  },
  10400: {
    title: 'MAI MULTE SEGMENTE',
    description: '',
    group: 'CT NATIV',
    subgroup: 'first opinion',
    price: 450,
  },
  10500: {
    title: '1 SEGMENT',
    description: 'exemplu: Abdomen',
    group: 'CT NATIV',
    subgroup: 'second opinion',
    price: 200,
  },
  10600: {
    title: '2 SEGMENTE',
    description: 'exemplu: Abdomen + Pelvis',
    group: 'CT NATIV',
    subgroup: 'second opinion',
    price: 275,
  },
  10700: {
    title: '3 SEGMENTE',
    description: 'exemplu: Torace, Abdomen si Pelvis',
    group: 'CT NATIV',
    subgroup: 'second opinion',
    price: 350,
  },
  10800: {
    title: 'MAI MULTE SEGMENTE',
    description: '',
    group: 'CT NATIV',
    subgroup: 'second opinion',
    price: 400,
  },

  // 2. CT CU SUBSTANTA DE CONTRAST
  11100: {
    title: '1 SEGMENT',
    description: 'exemplu: Abdomen',
    group: 'CT CU SUBSTANTA DE CONTRAST',
    subgroup: 'first opinion',
    price: 300,
  },
  11200: {
    title: '2 SEGMENTE',
    description: 'exemplu: Abdomen + Pelvis',
    group: 'CT CU SUBSTANTA DE CONTRAST',
    subgroup: 'first opinion',
    price: 450,
  },
  11300: {
    title: '3 SEGMENTE',
    description: 'exemplu: Torace, Abdomen si Pelvis',
    group: 'CT CU SUBSTANTA DE CONTRAST',
    subgroup: 'first opinion',
    price: 600,
  },
  11400: {
    title: 'MAI MULTE SEGMENTE',
    description: '',
    group: 'CT CU SUBSTANTA DE CONTRAST',
    subgroup: 'first opinion',
    price: 700,
  },
  11500: {
    title: '1 SEGMENT',
    description: 'exemplu: Abdomen',
    group: 'CT CU SUBSTANTA DE CONTRAST',
    subgroup: 'second opinion',
    price: 250,
  },
  11600: {
    title: '2 SEGMENTE',
    description: 'exemplu: Abdomen + Pelvis',
    group: 'CT CU SUBSTANTA DE CONTRAST',
    subgroup: 'second opinion',
    price: 400,
  },
  11700: {
    title: '3 SEGMENTE',
    description: 'exemplu: Torace, Abdomen si Pelvis',
    group: 'CT CU SUBSTANTA DE CONTRAST',
    subgroup: 'second opinion',
    price: 550,
  },
  11800: {
    title: 'MAI MULTE SEGMENTE',
    description: '',
    group: 'CT CU SUBSTANTA DE CONTRAST',
    subgroup: 'second opinion',
    price: 650,
  },
  // Angio CT
  12100: {
    title: 'ANGIO CT',
    description: '',
    group: 'ANGIO CT',
    subgroup: 'first opinion',
    price: 450,
  },
  12200: {
    title: 'ANGIO CT',
    description: '',
    group: 'ANGIO CT',
    subgroup: 'second opinion',
    price: 400,
  },
  13100: {
    title: 'Reinterpretare Achizitie CT',
    description: 'investigarea a aceleiasi regiuni la un interval de timp',
    group: 'Reinterpretare Achizitie CT',
    subgroup: '',
    price: 450,
  },
  // RMN NATIV
  14100: {
    title: '1 SEGMENT',
    description: 'exemplu: Abdomen',
    group: 'RMN NATIV',
    subgroup: 'first opinion',
    price: 400,
  },
  14200: {
    title: '2 SEGMENTE',
    description: 'exemplu: Abdomen + Pelvis',
    group: 'RMN NATIV',
    subgroup: 'first opinion',
    price: 550,
  },
  14300: {
    title: '3 SEGMENTE',
    description: 'exemplu: Torace, Abdomen si Pelvis',
    group: 'RMN NATIV',
    subgroup: 'first opinion',
    price: 675,
  },
  14400: {
    title: 'MAI MULTE SEGMENTE',
    description: '',
    group: 'RMN NATIV',
    subgroup: 'first opinion',
    price: 750,
  },
  14500: {
    title: '1 SEGMENT',
    description: 'exemplu: Abdomen',
    group: 'RMN NATIV',
    subgroup: 'second opinion',
    price: 350,
  },
  14600: {
    title: '2 SEGMENTE',
    description: 'exemplu: Abdomen + Pelvis',
    group: 'RMN NATIV',
    subgroup: 'second opinion',
    price: 500,
  },
  14700: {
    title: '3 SEGMENTE',
    description: 'exemplu: Torace, Abdomen si Pelvis',
    group: 'RMN NATIV',
    subgroup: 'second opinion',
    price: 625,
  },
  14800: {
    title: 'MAI MULTE SEGMENTE',
    description: '',
    group: 'RMN NATIV',
    subgroup: 'second opinion',
    price: 700,
  },
  // RMN CU SUBSTANTA DE CONTRAST
  15100: {
    title: '1 SEGMENT',
    description: 'exemplu: Abdomen',
    group: 'RMN CU SUBSTANTA DE CONTRAST',
    subgroup: 'first opinion',
    price: 500,
  },
  15200: {
    title: '2 SEGMENTE',
    description: 'exemplu: Abdomen + Pelvis',
    group: 'RMN CU SUBSTANTA DE CONTRAST',
    subgroup: 'first opinion',
    price: 650,
  },
  15300: {
    title: '3 SEGMENTE',
    description: 'exemplu: Torace, Abdomen si Pelvis',
    group: 'RMN CU SUBSTANTA DE CONTRAST',
    subgroup: 'first opinion',
    price: 750,
  },
  15400: {
    title: 'MAI MULTE SEGMENTE',
    description: '',
    group: 'RMN CU SUBSTANTA DE CONTRAST',
    subgroup: 'first opinion',
    price: 825,
  },
  15500: {
    title: '1 SEGMENT',
    description: 'exemplu: Abdomen',
    group: 'RMN CU SUBSTANTA DE CONTRAST',
    subgroup: 'second opinion',
    price: 450,
  },
  15600: {
    title: '2 SEGMENTE',
    description: 'exemplu: Abdomen + Pelvis',
    group: 'RMN CU SUBSTANTA DE CONTRAST',
    subgroup: 'second opinion',
    price: 600,
  },
  15700: {
    title: '3 SEGMENTE',
    description: 'exemplu: Torace, Abdomen si Pelvis',
    group: 'RMN CU SUBSTANTA DE CONTRAST',
    subgroup: 'second opinion',
    price: 700,
  },
  15800: {
    title: 'MAI MULTE SEGMENTE',
    description: '',
    group: 'RMN CU SUBSTANTA DE CONTRAST',
    subgroup: 'second opinion',
    price: 775,
  },
  // Angio RMN
  16100: {
    title: 'ANGIO RMN',
    description: '',
    group: 'ANGIO RMN',
    subgroup: 'first opinion',
    price: 500,
  },
  16200: {
    title: 'ANGIO RMN',
    description: '',
    group: 'ANGIO RMN',
    subgroup: 'second opinion',
    price: 450,
  },
  // Reinterpretare Achizitie RMN
  17100: {
    title: 'Reinterpretare Achizitie RMN',
    description: 'investigarea a aceleiasi regiuni la un interval de timp',
    group: 'Reinterpretare Achizitie RMN',
    subgroup: '',
    price: 600,
  },
  // RX (Radiografie)
  18100: {
    title: '1 SEGMENT',
    description: 'exemplu: Abdomen',
    group: 'RX (Radiografie)',
    subgroup: 'first opinion',
    price: 120,
  },
  18200: {
    title: '2 SEGMENTE',
    description: 'exemplu: Abdomen + Pelvis',
    group: 'RX (Radiografie)',
    subgroup: 'first opinion',
    price: 200,
  },
  18300: {
    title: '3 SEGMENTE',
    description: 'exemplu: Torace, Abdomen si Pelvis',
    group: 'RX (Radiografie)',
    subgroup: 'first opinion',
    price: 280,
  },
  18400: {
    title: 'MAI MULTE SEGMENTE',
    description: '',
    group: 'RX (Radiografie)',
    subgroup: 'first opinion',
    price: 360,
  },
  18500: {
    title: '1 SEGMENT',
    description: 'exemplu: Abdomen',
    group: 'RX (Radiografie)',
    subgroup: 'second opinion',
    price: 90,
  },
  18600: {
    title: '2 SEGMENTE',
    description: 'exemplu: Abdomen + Pelvis',
    group: 'RX (Radiografie)',
    subgroup: 'second opinion',
    price: 170,
  },
  18700: {
    title: '3 SEGMENTE',
    description: 'exemplu: Torace, Abdomen si Pelvis',
    group: 'RX (Radiografie)',
    subgroup: 'second opinion',
    price: 250,
  },
  18800: {
    title: 'MAI MULTE SEGMENTE',
    description: '',
    group: 'RX (Radiografie)',
    subgroup: 'second opinion',
    price: 330,
  },
  // EKG
  19100: {
    title: 'EKG',
    description: 'Electrocardiograma',
    group: 'EKG',
    subgroup: '',
    price: 100,
  },
};

export const Extra = {
  0: {
    title: '3 zile',
    description: 'raspuns in maximum 3 zile lucratoare (48-72h)',
    price: 0,
  },
  1: {
    title: '2 zile',
    description: 'raspuns in maximum 2 zile lucratoare (24-48h)',
    price: 100,
  },
  2: {
    title: '1 zi',
    description: 'raspuns in maximum o zi lucratoare (2-24h)',
    price: 200,
  },
};

export const OldProducts = {
  100: {
    title: 'Ecografie abdomen inferior',
    description: '',
    price: 125,
  },
  200: {
    title: 'Ecografie abdomen superior',
    description: '',
    price: 150,
  },
  300: {
    title: 'Ecografie abdomen total',
    description: '',
    price: 185,
  },
  400: {
    title: 'Ecografie mamara',
    description: 'unilateral',
    price: 125,
  },
  500: {
    title: 'Ecografie mamara',
    description: 'bilateral',
    price: 175,
  },
  600: {
    title: 'Ecografie muschi - doua segmente',
    description:
      'nu parti moi, tendoane, articulatii, formatiuni tumorale, chiste, abcese, tumori',
    price: 175,
  },
  700: {
    title: 'Ecografie parti moi',
    description: 'muschi, gat, glande parotide, sublinguale, submaxilare',
    price: 125,
  },
  800: {
    title: 'Ecografie parti moi',
    description:
      'altele decat muschi, gat, glande parotide, sublinguale, submaxilare',
    price: 150,
  },
  900: {
    title: 'Ecografie reno-vezico-prostatica',
    description: '',
    price: 150,
  },
  1000: {
    title: 'Ecografie reno-pelvina',
    description: '',
    price: 150,
  },
  1100: {
    title: 'Ecografie reno-vezico-prostatica si reziduu vezical',
    description: '',
    price: 150,
  },
  1200: {
    title: 'Ecografie testiculara',
    description: '',
    price: 175,
  },
  1300: {
    title: 'Ecografie tiroida',
    description: '',
    price: 150,
  },
  1400: {
    title: 'Ecografie doppler',
    description: 'arterial si venos bilateral',
    price: 340,
  },
  1500: {
    title: 'Ecografie doppler',
    description: 'arterial si venos unilateral',
    price: 265,
  },
  1600: {
    title: 'Ecografie doppler',
    description: 'arterial/venos unilateral',
    price: 185,
  },
  1700: {
    title: 'Ecografie doppler',
    description: 'arterial/venos bilateral',
    price: 225,
  },
  2000: {
    title: 'Interpretare RMN second opinion',
    description: '',
    price: 299,
  },
  2100: {
    title: 'Interpretare RMN',
    description: '',
    price: 386,
  },
  3000: {
    title: 'Interpretare CT second opinion',
    description: '',
    price: 218,
  },
  3100: {
    title: 'Interpretare CT',
    description: '',
    price: 386,
  },
  4000: {
    title: 'Reinterpretarea Achizitie CT/RMN',
    description: '',
    price: 550,
  },
  4100: {
    title: 'Reinterpretarea Examinarilor comparative',
    description: '',
    price: 350,
  },
  5000: {
    title: 'Interpretare EKG',
    description: '',
    price: 50,
  },
  6000: {
    title: 'Radiografie Cardio-Pulmonara fata/profil',
    description: 'Radiografie Cardio-Pulmonara fata sau profil',
    price: 130,
  },
  6100: {
    title: 'Radiografie Cardio-Pulmonara fata+profil',
    description: 'Radiografie Cardio-Pulmonara fata si profil',
    price: 170,
  },
  6200: {
    title: 'Radiografie Cardio-Pulmonara fata+oblice (AD+AS)',
    description: 'Radiografie Cardio-Pulmonara fata si oblice (AD+AS)',
    price: 170,
  },
  6300: {
    title: 'Radiografie Cardio-Pulmonara fata+profil+oblice (AD+AS)',
    description: 'Radiografie Cardio-Pulmonara fata, profil si oblice (AD+AS)',
    price: 200,
  },
};
