/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';
import { Container, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, defineMessages } from 'react-intl';
import { compose } from 'redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import logoutSVG from '@plone/volto/icons/log-out.svg';
import userSVG from '@plone/volto/icons/user.svg';

import {
  Anontools,
  Logo,
  Icon,
  Navigation,
  SearchWidget,
} from '@plone/volto/components';

const messages = defineMessages({
  logout: {
    id: 'Logout',
    defaultMessage: 'Logout',
  },
  account: {
    id: 'My account',
    defaultMessage: 'My account',
  },
});

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const path = '/cont';
    return (
      <Segment basic className="header-wrapper" role="banner">
        <Container>
          <div className="header">
            <div className="logo-nav-wrapper">
              <div className="logo">
                <Logo />
              </div>
              <Navigation pathname={this.props.pathname} />
            </div>
            <div className="tools-search-wrapper">
              {!this.props.token ? (
                <div className="tools">
                  <Anontools />
                </div>
              ) : (
                <>
                  <Link id="header-account" to={`${flattenToAppURL(path)}`}>
                    <Icon
                      className="account"
                      name={userSVG}
                      size="30px"
                      title={this.props.intl.formatMessage(messages.account)}
                    />
                  </Link>
                  <Link id="header-logout" to={`${flattenToAppURL('/logout')}`}>
                    <Icon
                      className="logout"
                      name={logoutSVG}
                      size="30px"
                      title={this.props.intl.formatMessage(messages.logout)}
                    />
                  </Link>
                </>
              )}
              <div className="search">
                <SearchWidget />
              </div>
            </div>
          </div>
        </Container>
      </Segment>
    );
  }
}

export default compose(
  injectIntl,
  connect((state) => ({
    token: state.userSession.token,
  })),
)(Header);
