import React from 'react';
import { Container } from 'semantic-ui-react';
import OrderPreview from './OrderPreview';

const OrderReadyView = (props) => {
  const { status } = props;
  return (
    <Container>
      <OrderPreview
        fluid={true}
        status={status}
        {...props}
        showResults={true}
      />
    </Container>
  );
};

export default OrderReadyView;
