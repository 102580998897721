/**
 * Login container.
 * @module components/LoginForm/LoginForm
 */
import React from 'react';
import { injectIntl } from 'react-intl';
import AuthProviders from '@plone-collective/volto-authomatic/components/AuthProviders/AuthProviders';

/**
 * Login function.
 * @function LoginForm
 * @returns {JSX.Element} Markup of the Login page.
 */
function LoginForm({ loading, providers, action, onSelectProvider }) {
  return (
    <div id="page-login">
      {!loading && providers && (
        <AuthProviders
          providers={providers}
          action={action}
          onSelectProvider={onSelectProvider}
        />
      )}
    </div>
  );
}

export default injectIntl(LoginForm);
