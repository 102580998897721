import Login from './components/Login/Login';
import Logout from './components/Login/Logout';
import AccountView from './components/Order/AccountView';
import OrderView from './components/Order/OrderView';
import OrderThankYou from './components/Order/OrderThankYou';
import ProductsView from './components/Blocks/Products/View';
import ProductsEdit from './components/Blocks/Products/Edit';
import productsSVG from '@plone/volto/icons/images.svg';

const applyConfig = (config) => {
  config.settings.supportedLanguages = ['ro'];
  config.settings.defaultLanguage = 'ro';

  // Products Block
  config.blocks.blocksConfig.productsBlock = {
    id: 'productsBlock',
    title: 'Produse interpretare',
    icon: productsSVG,
    group: 'media',
    view: ProductsView,
    edit: ProductsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 0,
  };

  // Disable tags on View
  config.settings.showTags = false;

  // Enable Title block
  config.blocks.blocksConfig.title.restricted = false;

  // Enable description block (also for cypress)
  config.blocks.blocksConfig.description.restricted = false;
  config.blocks.requiredBlocks = [];

  // Date format for RO
  config.settings.dateLocale = 'ro';

  // GDPR - Google Analytics
  config.settings.DSGVOBanner = {
    ...config.settings.DSGVOBanner,
    trackingId: 'G-55QGYZ44ME',
    privacy_url: '/politica-si-confidentialitate',
  };

  // GDPR - Google Tag Manager
  config.settings.DSGVOBanner.tracker = {
    ...config.settings.DSGVOBanner.tracker,
    gtagOptions: {
      id: 'GTM-KQNXS4FX',
    }
  };

  config.views.contentTypesViews.account = AccountView;
  config.views.contentTypesViews.interpretare = OrderView;

  // Login
  config.addonRoutes = [
    { path: '/login', component: Login },
    { path: '/**/login', component: Login },
    { path: '/logout', component: Logout },
    { path: '/**/logout', component: Logout },
    { path: '/register', component: Login },
    { path: '/thank-you', component: OrderThankYou },
    { path: '/**/thank-you', component: OrderThankYou },
    ...config.addonRoutes,
  ];

  return config;
};

export default applyConfig;
