import React from 'react';
import {
  Button,
  Dimmer,
  Header,
  Icon,
  Label,
  Loader,
  Segment,
  Step,
  Table,
} from 'semantic-ui-react';
import { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@plone/volto/components';
import { createContent } from '@plone/volto/actions';
import { getBaseUrl, flattenToAppURL } from '@plone/volto/helpers';
import { OrderSchema } from './schema';
import { Products, Extra } from 'interpretare-policy/components/Order/products';

const messages = defineMessages({
  Send: {
    id: 'Send',
    defaultMessage: 'Send',
  },
});

// Hook
function usePrevious(value) {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const NewOrder = ({ location, history }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const createRequest = useSelector(
    (state) => state.content.subrequests.interpretare,
  );
  const prevRequest = usePrevious(createRequest);
  const content = useSelector(
    (state) => state.content.subrequests.interpretare?.data,
  );
  const schema = OrderSchema({ intl });
  const [showForm, setShowForm] = useState(false);
  const [product, setProduct] = useState(0);
  const [extra, setExtra] = useState(0);

  // On create new content
  React.useEffect(() => {
    if (
      prevRequest?.loading &&
      createRequest.loaded &&
      content['@type'] === 'interpretare'
    ) {
      history.push(flattenToAppURL(content['@id']));
    }
  }, [createRequest, prevRequest, content, history]);

  // Actions
  const onCancel = React.useCallback(() => {
    setProduct(0);
    setExtra(0);
    setShowForm(false);
  }, [setProduct, setShowForm]);

  const onAdd = React.useCallback(() => {
    setShowForm(true);
  }, [setShowForm]);

  const onProduct = React.useCallback(
    (pid) => {
      window.scrollTo(0, 0);
      setProduct(pid);
    },
    [setProduct],
  );

  const onExtra = React.useCallback(
    (eid) => {
      setExtra(eid);
    },
    [setExtra],
  );

  const onSubmit = React.useCallback(
    (data) => {
      const timestamp = new Date().getTime();
      dispatch(
        createContent(
          getBaseUrl(location.pathname),
          {
            ...data,
            title: `${data.first_name} ${data.last_name} ${timestamp}`,
            '@static_behaviors': null,
            '@type': 'interpretare',
            price: (Products[product].price + Extra[extra].price).toString(),
            product: product.toString(),
            extra: extra.toString(),
          },
          'interpretare',
        ),
      );
    },
    [location.pathname, dispatch, product, extra],
  );

  // Render
  return (
    <div className="order">
      {showForm || product > 0 ? (
        <Step.Group fluid>
          <Step active={product === 0}>
            <Icon name="heartbeat" color="pink" />
            <Step.Content>
              <Step.Title>
                <FormattedMessage id="New order" defaultMessage="New order" />
              </Step.Title>
              <Step.Description>
                <FormattedMessage
                  id="Select investigation type"
                  defaultMessage="Select investigation type"
                />
              </Step.Description>
            </Step.Content>
          </Step>

          <Step disabled={product === 0} active={product > 0}>
            <Icon
              name="address card outline"
              color={product > 0 ? 'pink' : 'grey'}
            />
            <Step.Content>
              <Step.Title>
                <FormattedMessage id="Billing" defaultMessage="Billing" />
              </Step.Title>
              <Step.Description>
                <FormattedMessage
                  id="Billing details"
                  defaultMessage="Billing details"
                />
              </Step.Description>
            </Step.Content>
          </Step>

          <Step disabled>
            <Icon name="payment" />
            <Step.Content>
              <Step.Title>
                <FormattedMessage id="Payment" defaultMessage="Payment" />
              </Step.Title>
              <Step.Description>
                <FormattedMessage
                  id="Payment details"
                  defaultMessage="Payment details"
                />
              </Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
      ) : (
        ''
      )}
      {/* Add new button */}
      {!showForm ? (
        <Segment textAlign="center" basic>
          <Button onClick={onAdd} color="red">
            <FormattedMessage id="New order" defaultMessage="New order" />
          </Button>
        </Segment>
      ) : product <= 0 ? (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage
                  id="Investigation name"
                  defaultMessage="Investigation name"
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage
                  id="Segments"
                  defaultMessage="Segments"
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage
                  id="Opinions"
                  defaultMessage="Opinions"
                />
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                <FormattedMessage id="Price" defaultMessage="Price" />
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                <FormattedMessage
                  id="Shopping cart"
                  defaultMessage="Shopping cart"
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.keys(Products).map((key) => {
              const ob = Products[key];
              return (
                <Table.Row key={key}>
                  <Table.Cell><strong>{ob.group}</strong></Table.Cell>
                  <Table.Cell>
                    <span>{ob.title}</span>
                    <p className="discreet">{ob.description}</p>
                  </Table.Cell>
                  <Table.Cell><span>{ob.subgroup}</span></Table.Cell>
                  <Table.Cell textAlign="center">{ob.price} RON</Table.Cell>
                  <Table.Cell>
                    <Button
                      floated="right"
                      primary
                      animated
                      onClick={() => onProduct(key)}
                    >
                      <Button.Content visible>
                        <FormattedMessage
                          id="Order now"
                          defaultMessage="Order now"
                        />
                      </Button.Content>
                      <Button.Content hidden>
                        <Icon name="shop" />
                      </Button.Content>
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
            {Object.keys(Extra).map((key) => {
              const ob = Extra[key];
              return (
                <Table.Row key={key}>
                  <Table.Cell>
                    <strong>
                      <FormattedMessage
                        id="Urgency tax: "
                        defaultMessage="Urgency tax: "
                      />
                      {ob.title}
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <p className="discreet">{ob.description}</p>
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell textAlign="center">+{ob.price} RON</Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="5" textAlign="right">
                <Button secondary onClick={onCancel}>
                  <FormattedMessage id="Cancel" defaultMessage="Cancel" />
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      ) : (
        <>
          <Dimmer active={createRequest?.loading}>
            <Loader>
              <FormattedMessage
                id="Request in progress"
                defaultMessage="Request in progress"
              />
            </Loader>
          </Dimmer>
          <Segment.Group>
            <Segment>
              <Header as="h2">{Products[product].title}</Header>
              <p className="discreet">{Products[product].description}</p>
              <p>
                <strong>
                  <FormattedMessage id="I want " defaultMessage="I want " />
                  {Extra[extra].description}
                </strong>
              </p>
              <Button.Group name="urgent">
                {Object.keys(Extra).map((key) => (
                  <Button
                    color="pink"
                    key={key}
                    onClick={() => onExtra(key)}
                    active={key === extra}
                  >
                    {Extra[key].title}
                  </Button>
                ))}
              </Button.Group>
            </Segment>
            <Segment textAlign="right">
              <Label basic color="pink" size="big">
                <FormattedMessage id="Total: " defaultMessage="Total: " />
                {Products[product].price + Extra[extra].price} RON
              </Label>
            </Segment>
          </Segment.Group>
          <Form
            onSubmit={onSubmit}
            onCancel={onCancel}
            loading={createRequest?.loading}
            submitLabel={intl.formatMessage(messages.Send)}
            schema={schema}
            formData={{}}
          />
        </>
      )}
    </div>
  );
};

export default NewOrder;
