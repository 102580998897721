import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import NewOrder from './NewOrder';
import MyOrders from './MyOrders';

const MemberView = (props) => {
  const user = useSelector((state) => state.users.user);
  const fullname = user?.fullname;
  const email = user?.email;
  return (
    <Container>
      <Segment textAlign="right" basic>
        <h1>{fullname}</h1>
        <p>{email}</p>
      </Segment>
      <NewOrder {...props} />
      <MyOrders {...props} />
    </Container>
  );
};

export default MemberView;
