import React from 'react';
import { Card, Label, Segment } from 'semantic-ui-react';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import FileViewWidget from '@plone/volto/components/theme/Widgets/FileWidget';
import {
  Products,
  Extra,
  OldProducts,
} from 'interpretare-policy/components/Order/products';

const messages = defineMessages({
  Product: {
    id: 'Product',
    defaultMessage: 'Product',
  },
  Address: {
    id: 'Address',
    defaultMessage: 'Address',
  },
  Phone: {
    id: 'Phone',
    defaultMessage: 'Phone',
  },
  Email: {
    id: 'E-mail',
    defaultMessage: 'E-mail',
  },
  CNP: {
    id: 'CNP',
    defaultMessage: 'CNP',
  },
  File: {
    id: 'Medical file',
    defaultMessage: 'Medical file',
  },
  ExtraFile: {
    id: 'Extra file',
    defaultMessage: 'Extra file',
  },

  // Results
  Result: {
    id: 'Result',
    defaultMessage: 'Result',
  },
  ResultFile: {
    id: 'Result file',
    defaultMessage: 'Result file',
  },

  // States
  canceled: {
    id: 'Canceled',
    defaultMessage: 'Canceled',
  },
  archived: {
    id: 'Archived',
    defaultMessage: 'Archived',
  },
  private: {
    id: 'Private',
    defaultMessage: 'Private',
  },
  pending_payment: {
    id: 'Pending payment confirmation',
    defaultMessage: 'Pending payment confirmation',
  },
  pending_assignment: {
    id: 'Pending assignment',
    defaultMessage: 'Pending assignment',
  },
  pending_results: {
    id: 'Pending results',
    defaultMessage: 'Pending results',
  },
  ready: {
    id: 'Results are ready',
    defaultMessage: 'Results are ready',
  },
});

const status_colors = {
  canceled: 'black',
  archived: 'gray',
  private: 'red',
  pending_payment: 'orange',
  pending_assignment: 'yellow',
  pending_results: 'teal',
  ready: 'green',
};

const OrderPreview = (props) => {
  const intl = useIntl();
  const { content, children, fluid, status, contact, showResults } = props;
  const product = content.product || 0;
  const extra = content.extra || 0;
  const color = status_colors[status] || 'pink';
  const label = messages[status]
    ? intl.formatMessage(messages[status])
    : status;

  return (
    <Card fluid={fluid}>
      <Card.Content>
        <Label color="pink" attached="top right">
          {Extra[extra]?.title}
        </Label>
        <Label basic color={color} attached="top left">
          {label}
        </Label>
        <Segment basic></Segment>
        <Card.Header>
          <UniversalLink href={content['@id']}>
            {content.first_name} {content.last_name}
          </UniversalLink>
        </Card.Header>
        <Card.Description>{content.history}</Card.Description>
      </Card.Content>
      <Card.Content extra title={intl.formatMessage(messages.Product)}>
        <i className="images outline icon"></i>
        {Products[product]?.group} {Products[product]?.title} {Products[product]?.subgroup}
        {OldProducts[product]?.title} {OldProducts[product]?.description}
      </Card.Content>
      <Card.Content extra title={intl.formatMessage(messages.CNP)}>
        <i className="address card outline icon"></i>
        {content.cnp}
      </Card.Content>
      <Card.Content extra title={intl.formatMessage(messages.File)}>
        <i className="file archive icon"></i>
        <FileViewWidget value={content.file} />
      </Card.Content>
      {/* Extra file */}
      {content.extra_file && (
        <Card.Content extra title={intl.formatMessage(messages.ExtraFile)}>
          <i className="file archive icon"></i>
          <FileViewWidget value={content.extra_file} />
        </Card.Content>
      )}
      {/* Result text */}
      {showResults && content.result && (
        <Card.Content extra title={intl.formatMessage(messages.Result)}>
          <h3>
            <FormattedMessage id="Result" defaultMessage="Result" />
          </h3>
          <p>{content.result}</p>
        </Card.Content>
      )}
      {/* Results file */}
      {showResults && content.result_file && (
        <Card.Content extra title={intl.formatMessage(messages.ResultFile)}>
          <i className="file pdf icon"></i>
          <FileViewWidget value={content.result_file} />
        </Card.Content>
      )}
      {/* Contact info */}
      {contact && (
        <>
          <Card.Content extra title={intl.formatMessage(messages.Address)}>
            <i className="home icon"></i>
            {content.address}
          </Card.Content>
          <Card.Content extra title={intl.formatMessage(messages.Phone)}>
            <i className="phone icon"></i>
            {content.phone}
          </Card.Content>
          <Card.Content extra title={intl.formatMessage(messages.Email)}>
            <i className="mail icon"></i>
            {content.email}
          </Card.Content>
        </>
      )}
      {/* Actions */}
      {children && <Card.Content extra>{children}</Card.Content>}
    </Card>
  );
};

export default OrderPreview;
