/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment } from 'semantic-ui-react';
import { map } from 'lodash';
import { injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import anpcSVG from 'interpretare-theme/icons/anpc.svg';
import solSVG from 'interpretare-theme/icons/sol.svg';
import netopiaSVG from 'interpretare-theme/icons/netopia.svg';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { settings } = config;
  const { lang, siteActions = [] } = useSelector(
    (state) => ({
      lang: state.intl.locale,
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );

  return (
    <Segment
      role="contentinfo"
      vertical
      padded
      inverted
      color="red"
      textAlign="center"
      id="footer"
    >
      <Container>
        <List horizontal inverted>
          {siteActions?.length
            ? map(siteActions, (item) => (
                <div role="listitem" className="item" key={item.id}>
                  <UniversalLink
                    className="item"
                    href={
                      settings.isMultilingual
                        ? `/${lang}/${
                            item.url
                              ? flattenToAppURL(item.url)
                              : addAppURL(item.id)
                          }`
                        : item.url
                        ? flattenToAppURL(item.url)
                        : addAppURL(item.id)
                    }
                  >
                    {item?.title}
                  </UniversalLink>
                </div>
              ))
            : null}
        </List>
      </Container>
      <Container className="payment-wrapper">
        <Icon name={netopiaSVG} size="50px" />
      </Container>
      <Container className="anpc-sol-wrapper">
        <a
          href="https://anpc.ro/ce-este-sal/"
          target="_blank"
          rel="norefferer"
          title="Soluționarea Alternativă a Litigiilor"
        >
          <Icon name={anpcSVG} size="62px" />
        </a>
        <a
          href="https://ec.europa.eu/consumers/odr"
          target="_blank"
          rel="nofollow"
          title="Soluționarea online a litigiilor"
        >
          <Icon name={solSVG} size="62px" />
        </a>
      </Container>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
