import React from 'react';
import { Container } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const OrderUnknownView = ({ status }) => {
  return (
    <Container>
      <FormattedMessage
        id="Unknown View for order state: {status}"
        defaultMessage="Unknown View for order state: {status}"
        values={{ status: status }}
      />
    </Container>
  );
};

export default OrderUnknownView;
