import React from 'react';
import { Table } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Products, Extra } from 'interpretare-policy/components/Order/products';

const ProductsView = () => {
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedMessage
              id="Investigation name"
              defaultMessage="Investigation name"
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage
              id="Segments"
              defaultMessage="Segments"
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage
              id="Opinions"
              defaultMessage="Opinions"
            />
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            <FormattedMessage id="Price" defaultMessage="Price" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.keys(Products).map((key) => {
          const ob = Products[key];
          return (
            <Table.Row key={key}>
              <Table.Cell>
                <strong>{ob.group}</strong>
              </Table.Cell>
              <Table.Cell>
                <span>{ob.title}</span>
                <p className="discreet">{ob.description}</p>
              </Table.Cell>
              <Table.Cell>
                <span>{ob.subgroup}</span>
              </Table.Cell>
              <Table.Cell textAlign="center">{ob.price} RON</Table.Cell>
            </Table.Row>
          );
        })}
        {Object.keys(Extra).map((key) => {
          const ob = Extra[key];
          return (
            <Table.Row key={key}>
              <Table.Cell>
                <strong>
                  <FormattedMessage
                    id="Urgency tax: "
                    defaultMessage="Urgency tax: "
                  />
                  {ob.title}
                </strong>
              </Table.Cell>
              <Table.Cell>
                <p className="discreet">{ob.description}</p>
              </Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell textAlign="center">+{ob.price} RON</Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default ProductsView;
