import React, { useState } from 'react';
import { Button, Container, Dimmer, Loader } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { updateContent, transitionWorkflow } from '@plone/volto/actions';
import { getBaseUrl, getParentUrl } from '@plone/volto/helpers';
import { Form } from '@plone/volto/components';
import OrderPreview from './OrderPreview';
import { ResultSchema } from './schema';

const messages = defineMessages({
  Send: {
    id: 'Send',
    defaultMessage: 'Send',
  },
  New: {
    id: 'New order',
    defaultMessage: 'New order',
  },
});

// Hook
function usePrevious(value) {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const OrderPendingView = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.users?.user?.roles || []);

  const updateRequest = useSelector((state) => state.content.update);
  const updatePrevious = usePrevious(updateRequest);

  const transitionRequest = useSelector((state) => state.workflow.transition);
  const transitionPrevious = usePrevious(transitionRequest);

  const [showForm, setShowForm] = useState(false);

  const { status, location, history } = props;
  const medic = roles?.includes('Reviewer');
  const schema = ResultSchema({ intl });

  // On update content
  React.useEffect(() => {
    if (updatePrevious?.loading && updateRequest.loaded) {
      dispatch(
        transitionWorkflow(
          `${getBaseUrl(location.pathname)}/@workflow/publish`,
        ),
      );
    }
  }, [updateRequest, updatePrevious, history, location, dispatch]);

  // On state change
  React.useEffect(() => {
    if (transitionPrevious?.loading && transitionRequest.loaded) {
      history.push(getParentUrl(location.pathname));
    }
  }, [transitionRequest, transitionPrevious, history, location]);

  // Add results form
  const onResults = React.useCallback(() => {
    setShowForm(true);
  }, []);

  // Submit results from
  const onSubmit = React.useCallback(
    (data) => {
      dispatch(updateContent(getBaseUrl(location.pathname), data));
    },
    [location.pathname, dispatch],
  );

  // Cancel
  const onCancel = React.useCallback(() => {
    setShowForm(false);
  }, []);

  // Return
  if (!medic) {
    return (
      <Container>
        <OrderPreview fluid={true} status={status} {...props} />
      </Container>
    );
  } else {
    return (
      <Container>
        <OrderPreview fluid={true} status={status} {...props}>
          {showForm ? (
            <>
              <Dimmer
                active={updateRequest?.loading || transitionRequest?.loading}
              >
                <Loader>
                  <FormattedMessage
                    id="Request in progress"
                    defaultMessage="Request in progress"
                  />
                </Loader>
              </Dimmer>
              <Form
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={updateRequest?.loading || transitionRequest?.loading}
                submitLabel={intl.formatMessage(messages.Send)}
                schema={schema}
                formData={{}}
              />
            </>
          ) : (
            <Button onClick={onResults} color="green" fluid>
              <FormattedMessage id="Add results" defaultMessage="Add results" />
            </Button>
          )}
        </OrderPreview>
      </Container>
    );
  }
};

export default OrderPendingView;
