import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import OrdersPreview from './OrdersPreview';

const PendingPatients = ({ orders }) => {
  return (
    <Segment basic>
      <Header as="h2">
        <FormattedMessage
          id="Waiting patients"
          defaultMessage="Waiting patients"
        />
      </Header>
      <Segment basic textAlign="center">
        <OrdersPreview orders={orders} />
      </Segment>
    </Segment>
  );
};

export default PendingPatients;
