/**
 * Login container.
 * @module components/Login/Login
 */
import React, { useEffect, useState } from 'react';
import {
  authomaticRedirect,
  listAuthOptions,
} from '@plone-collective/volto-authomatic/actions';
import {
  Tab,
  Container,
  Segment,
  Divider,
  Checkbox,
  Header,
} from 'semantic-ui-react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import LoginForm from './LoginForm';
import EmailLogin from './EmailLogin';
import EmailRegister from './EmailRegister';

const messages = defineMessages({
  login: {
    id: 'Login',
    defaultMessage: 'Login',
  },
  register: {
    id: 'Register',
    defaultMessage: 'Register',
  },
  or: {
    id: 'OR',
    defaultMessage: 'OR',
  },
  login_widh: {
    id: 'Log in with',
    defaultMessage: 'Log in widh',
  },
  agree: {
    id: 'I have read and agree with',
    defaultMessage: 'I have read and agree with',
  },
  redirect: {
    id: 'You will be redirected to the login form in {count} seconds.',
    defaultMessage:
      'You will be redirected to the login form in {count} seconds.',
  },
  thanks: {
    id: 'Thank you.',
    defaultMessage: 'Thank you.',
  },
});

/**
 * Login function.
 * @function Login
 * @returns {JSX.Element} Markup of the Login page.
 */
function Login({ intl }) {
  const dispatch = useDispatch();
  const [startedOAuth, setStartedOAuth] = useState(false);
  const loading = useSelector((state) => state.authOptions.loading);
  const options = useSelector((state) => state.authOptions.options);
  const loginValues = useSelector((state) => state.authomaticRedirect);
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    dispatch(listAuthOptions());
  }, [dispatch]);

  useEffect(() => {
    const next_url = loginValues.next_url;
    const session = loginValues.session;
    if (next_url && session && startedOAuth) {
      setStartedOAuth(false);
      // Give time to save state to localstorage
      setTimeout(function () {
        window.location.href = next_url;
      }, 500);
    }
  }, [startedOAuth, loginValues]);

  const onSelectProvider = (provider) => {
    setStartedOAuth(true);
    dispatch(authomaticRedirect(provider.id));
  };

  const onAgree = React.useCallback((evt, data) => {
    setAgree(!!data.checked);
  }, []);

  return (
    <Container text style={{ marginTop: '2rem' }}>
      <Header as="h1">
        <FormattedMessage id="Login" defaultMessage="Login" />
      </Header>
      <Segment>
        <Checkbox
          onChange={onAgree}
          label={intl.formatMessage(messages.agree)}
        />{' '}
        <UniversalLink href="/conditii-de-utilizare">
          <FormattedMessage
            id="Terms and conditions"
            defaultMessage="Terms and conditions"
          />
        </UniversalLink>{' '}
        <FormattedMessage id="and" defaultMessage="and" />{' '}
        <UniversalLink href="/politica-si-confidentialitate">
          <FormattedMessage
            id="Privacy policy"
            defaultMessage="Privacy policy"
          />
        </UniversalLink>
      </Segment>
      {agree && (
        <Segment>
          <Tab
            menu={{ secondary: true, pointing: true }}
            menuPosition="right"
            className="login-tabs"
            panes={[
              {
                menuItem: intl.formatMessage(messages.login),
                render: () => {
                  return (
                    <Tab.Pane>
                      {' '}
                      <LoginForm
                        loading={loading}
                        providers={options}
                        action={'login'}
                        onSelectProvider={onSelectProvider}
                      />
                      <Divider horizontal>
                        {intl.formatMessage(messages.or)}
                      </Divider>
                      <EmailLogin />
                    </Tab.Pane>
                  );
                },
              },
              {
                menuItem: intl.formatMessage(messages.register),
                render: () => {
                  return (
                    <Tab.Pane>
                      {' '}
                      <LoginForm
                        loading={loading}
                        providers={options}
                        action={'login'}
                        onSelectProvider={onSelectProvider}
                      />
                      <Divider horizontal>
                        {intl.formatMessage(messages.or)}
                      </Divider>
                      <EmailRegister />
                    </Tab.Pane>
                  );
                },
              },
            ]}
          />
        </Segment>
      )}
    </Container>
  );
}

export default injectIntl(Login);
