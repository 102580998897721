import React, { useState } from 'react';
import { Container, Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { getContent } from '@plone/volto/actions';
import MyPatients from './MyPatients';
import PendingPatients from './PendingPatients';

const MedicView = (props) => {
  const user = useSelector((state) => state.users.user);
  const fullname = user?.fullname;
  const email = user?.email;

  const dispatch = useDispatch();
  const subrequests = useSelector((state) => state.content.subrequests || {});
  const { content = {} } = props;
  const { items = [] } = content;
  const [orders, setOrders] = useState([]);

  React.useEffect(() => {
    items.forEach((item) => {
      const url = item['url'];
      dispatch(getContent(url, null, url));
    });
  }, [items, dispatch]);

  React.useEffect(() => {
    setOrders(
      items
        .map((item) => {
          if (!subrequests?.[item.url]?.loaded) {
            return false;
          }
          const data = subrequests[item.url].data;
          const status = data['review_state'];
          return { data, status };
        })
        .filter((item) => item),
    );
  }, [items, subrequests, setOrders]);

  return (
    <Container>
      <Segment textAlign="right" basic>
        <h1>{fullname}</h1>
        <p>{email}</p>
      </Segment>
      <MyPatients
        orders={orders.filter(
          (order) =>
            order.data?.contributors?.includes(user.username) &&
            order.data?.review_state === 'pending_results',
        )}
      />
      <PendingPatients
        orders={orders.filter(
          (order) => order.data?.review_state === 'pending_assignment',
        )}
      />
    </Container>
  );
};

export default MedicView;
