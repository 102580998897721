import React from 'react';
import OrderCanceledView from './OrderCanceledView';
import OrderPrivateView from './OrderPrivateView';
import OrderConfirmView from './OrderConfirmView';
import OrderAssignView from './OrderAssignView';
import OrderPendingView from './OrderPendingView';
import OrderReadyView from './OrderReadyView';
import OrderArchivedView from './OrderArchivedView';
import OrderUnknownView from './OrderUnknownView';

const variations = {
  canceled: OrderCanceledView,
  private: OrderPrivateView,
  pending_payment: OrderConfirmView,
  pending_assignment: OrderAssignView,
  pending_results: OrderPendingView,
  ready: OrderReadyView,
  archived: OrderArchivedView,
};

const OrderView = (props) => {
  const review_state = props.content.review_state;
  const View = variations[review_state] || OrderUnknownView;
  return <View status={review_state} {...props} />;
};

export default OrderView;
