import React from 'react';
import { Container, Button, Form, Icon, Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getNetopiaSign } from '@plone-collective/volto-netopia/actions';
import OrderPreview from './OrderPreview';

const OrderPrivateView = (props) => {
  const { status, content } = props;
  const dispatch = useDispatch();
  const signed_order = useSelector((state) => state.netopiaSign);
  const { location } = props;

  React.useEffect(() => {
    dispatch(getNetopiaSign(location.pathname));
  }, [dispatch, location.pathname]);

  return (
    <Container>
      <OrderPreview fluid={true} status={status} {...props}>
        <Form action={signed_order?.server} method="post">
          <input type="hidden" name="env_key" value={signed_order?.env_key} />
          <input type="hidden" name="data" value={signed_order?.data} />
          <Segment floated="right" basic>
            <strong>
              <FormattedMessage id="Total:" defaultMessage="Total:" />
            </strong>{' '}
            <span>{content.price}</span> <span>{content.currency}</span>
          </Segment>
          <Form.Button type="submit" color="red" floated="right" animated>
            <Button.Content visible>
              <FormattedMessage id="Pay now" defaultMessage="Pay now" />
            </Button.Content>
            <Button.Content hidden>
              <Icon name="shop" />
            </Button.Content>
          </Form.Button>
        </Form>
      </OrderPreview>
    </Container>
  );
};

export default OrderPrivateView;
