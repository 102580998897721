import React, { useState } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getContent } from '@plone/volto/actions';
import OrdersPreview from './OrdersPreview';

const MyOrders = (props) => {
  const dispatch = useDispatch();
  const subrequests = useSelector((state) => state.content.subrequests || {});
  const { content = {} } = props;
  const { items = [] } = content;
  const [orders, setOrders] = useState([]);

  React.useEffect(() => {
    items.forEach((item) => {
      const url = item['url'];
      dispatch(getContent(url, null, url));
    });
  }, [items, dispatch]);

  React.useEffect(() => {
    setOrders(
      items
        .map((item) => {
          if (!subrequests?.[item.url]?.loaded) {
            return false;
          }
          const data = subrequests[item.url].data;
          const status = data['review_state'];
          return { data, status };
        })
        .filter((item) => item),
    );
  }, [items, subrequests, setOrders]);

  return (
    <Segment basic>
      <Header as="h2">
        <FormattedMessage
          id="My appointments"
          defaultMessage="My appointments"
        />
      </Header>
      <Segment basic textAlign="center">
        <OrdersPreview orders={orders} />
      </Segment>
    </Segment>
  );
};

export default MyOrders;
