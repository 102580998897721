import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Title: {
    id: 'Order',
    defaultMessage: 'Order',
  },
  FirstName: {
    id: 'First Name',
    defaultMessage: 'First Name',
  },
  LastName: {
    id: 'Last Name',
    defaultMessage: 'Last Name',
  },
  Address: {
    id: 'Address',
    defaultMessage: 'Address',
  },
  Phone: {
    id: 'Phone',
    defaultMessage: 'Phone',
  },
  Email: {
    id: 'E-mail',
    defaultMessage: 'E-mail',
  },
  CNP: {
    id: 'CNP',
    defaultMessage: 'CNP',
  },
  History: {
    id: 'Medical history',
    defaultMessage: 'Medical history',
  },
  File: {
    id: 'Medical file',
    defaultMessage: 'Medical file',
  },
  FileHelp: {
    id: 'You can add one medical file of type .zip',
    defaultMessage: 'You can add one medical file of type .zip',
  },
  ExtraFile: {
    id: 'Extra file',
    defaultMessage: 'Extra file',
  },
  ExtraFileHelp: {
    id: 'You can add one extra medical file of type .zip',
    defaultMessage: 'You can add one extra medical file of type .zip',
  },
  Results: {
    id: 'Results',
    defaultMessage: 'Results',
  },
  Result: {
    id: 'Result',
    defaultMessage: 'Result',
  },
  ResultFile: {
    id: 'Result file',
    defaultMessage: 'Result file',
  },
  ResultFileHelp: {
    id: 'You can add one extra medical file of type .pdf, .png, .jpg',
    defaultMessage:
      'You can add one extra medical file of type .pdf, .png, .jpg',
  },
});

export const OrderSchema = ({ intl }) => {
  return {
    title: intl.formatMessage(messages.Title),
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.Title),
        fields: [
          'first_name',
          'last_name',
          'cnp',
          'address',
          'phone',
          'email',
          'history',
          'file',
          'extra_file',
        ],
      },
    ],
    properties: {
      first_name: {
        title: intl.formatMessage(messages.FirstName),
      },
      last_name: {
        title: intl.formatMessage(messages.LastName),
      },
      address: {
        title: intl.formatMessage(messages.Address),
      },
      phone: {
        title: intl.formatMessage(messages.Phone),
        type: 'number',
      },
      email: {
        title: intl.formatMessage(messages.Email),
        widget: 'email',
      },
      cnp: {
        title: intl.formatMessage(messages.CNP),
        type: 'number',
      },
      history: {
        title: intl.formatMessage(messages.History),
        widget: 'textarea',
      },
      file: {
        title: intl.formatMessage(messages.File),
        description: intl.formatMessage(messages.FileHelp),
        widget: 'file',
      },
      extra_file: {
        title: intl.formatMessage(messages.ExtraFile),
        description: intl.formatMessage(messages.ExtraFileHelp),
        widget: 'file',
      },
    },
    required: [
      'first_name',
      'last_name',
      'address',
      'phone',
      'email',
      'cnp',
      'history',
      'file',
    ],
  };
};

export const ResultSchema = ({ intl }) => {
  return {
    title: intl.formatMessage(messages.Results),
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.Result),
        fields: ['result', 'result_file'],
      },
    ],
    properties: {
      result: {
        title: intl.formatMessage(messages.Result),
        widget: 'textarea',
      },
      result_file: {
        title: intl.formatMessage(messages.ResultFile),
        description: intl.formatMessage(messages.ResultFileHelp),
        widget: 'file',
      },
    },
    required: ['result'],
  };
};
