import React, { useState } from 'react';
import { Container, Button } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getBaseUrl, getParentUrl } from '@plone/volto/helpers';
import { transitionWorkflow } from '@plone/volto/actions';

import OrderPreview from './OrderPreview';

// Hook
function usePrevious(value) {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const OrderAssignView = (props) => {
  const dispatch = useDispatch();
  const { status, location, history } = props;
  const roles = useSelector((state) => state.users?.user?.roles || []);
  const transitionRequest = useSelector((state) => state.workflow.transition);
  const prevRequest = usePrevious(transitionRequest);
  const medic = roles?.includes('Reviewer');
  const [loading, setLoading] = useState(false);

  // Redirect to my account on assign
  React.useEffect(() => {
    if (prevRequest?.loading && transitionRequest.loaded) {
      history.push(getParentUrl(location.pathname));
    }
  }, [prevRequest, transitionRequest, location.pathname, history]);

  const onAssign = React.useCallback(() => {
    setLoading(true);
    dispatch(
      transitionWorkflow(`${getBaseUrl(location.pathname)}/@workflow/assign`),
    );
  }, [location.pathname, dispatch]);

  return (
    <Container>
      <OrderPreview fluid={true} status={status} {...props}>
        {medic ? (
          <Button onClick={onAssign} color="orange" fluid loading={loading}>
            <FormattedMessage id="Assign" defaultMessage="Assign" />
          </Button>
        ) : (
          ''
        )}
      </OrderPreview>
    </Container>
  );
};

export default OrderAssignView;
