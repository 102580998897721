import React from 'react';
import { Container, Segment, Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';

const OrderThankYou = () => {
  return (
    <Container>
      <Segment>
        <Header as="h1">
          <FormattedMessage id="Thank you" defaultMessage="Thank you" />
        </Header>
        <p>
          <FormattedMessage
            id="You have successfully completed this order."
            defaultMessage="You have successfully completed this order."
          />
        </p>
        <p>
          <FormattedMessage
            id="Thank you for your trust!"
            defaultMessage="Thank you for your trust!"
          />
        </p>
        <p>
          <FormattedMessage
            id="A doctor will take your request shortly and you will receive an email notification when the results are ready."
            defaultMessage="A doctor will take your request shortly and you will receive an email notification when the results are ready."
          />
        </p>
        <UniversalLink href="/cont">
          <FormattedMessage id="My account" defaultMessage="My account" />
        </UniversalLink>
      </Segment>
    </Container>
  );
};

export default OrderThankYou;
