import React from 'react';
import { Segment, Card } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import OrderPreview from './OrderPreview';

const OrdersPreview = ({ orders }) => {
  return (
    <>
      {orders?.length ? (
        <Card.Group>
          {orders.map(({ data, status }) => {
            return (
              <OrderPreview status={status} content={data} key={data.id} />
            );
          })}
        </Card.Group>
      ) : (
        <Segment basic>
          <FormattedMessage id="No entries" defaultMessage="No entries" />
        </Segment>
      )}
    </>
  );
};

export default OrdersPreview;
