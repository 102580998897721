import React from 'react';
import { Container, Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import OrderPreview from './OrderPreview';

const OrderArchivedView = (props) => {
  const { status } = props;
  const onUnarchive = React.useCallback(() => {
    // eslint-disable-next-line no-console
    console.log(props);
  }, [props]);
  return (
    <Container>
      <OrderPreview fluid={true} status={status} {...props}>
        <Button onClick={onUnarchive} color="orange" fluid>
          <FormattedMessage id="Unarchive" defaultMessage="Unarchive" />
        </Button>
      </OrderPreview>
    </Container>
  );
};

export default OrderArchivedView;
